import React from 'react';
import { graphql } from 'gatsby';
import { Link } from '@chakra-ui/react';

import Footer from '../../../components/Footer';
import Header from '../../../components/Header';
import Layout from '../../../components/atoms/layout';
import PageTitle from '../../../components/PageTitle';
import { Question } from '../../../template/legal/styles';
import TermOfUseProps from '../../../interface/template';
import { Text2, Text3 } from '../../../components/atoms/body';
import withI18next from '../../../i18n/TemplateI18n';

const PrivacyPolicy: React.SFC<any> = (props: TermOfUseProps) => {
  const { pageContext } = props;
  const { url, pageName } = pageContext;

  return (
    <>
      <Header pageName={pageName} showBackground url={url}>
        <PageTitle subtitle="" title="Privacy Policy" />
      </Header>
      <Layout>
        <Question>
          <Text3 as="p">
            <strong>DATENSCHUTZERKLÄRUNG</strong>
          </Text3>
        </Question>
        <Question>
          <Text2 as="h2">IDENTIFIZIERUNG</Text2>
          <Text3 as="p">
            <ul>
              <li>
                <strong>Verantwortlich für die Verarbeitung</strong>: Ironhack
                Group INC Sucursal en España (im weiteren “Ironhack”)
              </li>
              <li>
                <strong>Steuerident.-Nr.: </strong>W4009352H
              </li>
              <li>
                <strong>Sitz</strong>: C/ CASTELLO, 115, BAJOS 7 - 28006 MADRID
              </li>
              <li>
                <strong>Datenschutzbeauftragter (DSB)</strong>: Der Nutzer kann
                sich an den DSB mit einem Schreiben an den Sitz von Ironhack zu
                Hd. des “Datenschutzbeauftragten” richten oder per E-Mail an{' '}
                <a href="mailto:legal@ironahack.com">legal@ironahack.com</a>
              </li>
            </ul>
          </Text3>
        </Question>
        <Question>
          <Text2 as="h2">INFORMATION UND EINWILLIGUNG</Text2>
          <Text3 as="p">
            Wenn der Nutzer diese Datenschutzerklärung akzeptiert, gilt er als
            davon in Kenntnis gesetzt und gibt seine unterrichtete,
            ausdrückliche, freie und eindeutige Einwilligung, damit die
            personenbezogenen Daten, die er über die Formulare der Webseite
            unter der URL{' '}
            <a href="https://www.ironhack.com/de">www.ironhack.com</a> (im
            weiteren die “Website”) übermittelt, von Ironhack verarbeitet werden
            können.
          </Text3>
          <Text3 as="p">
            Ironhack haftet nicht für die Verarbeitung von Daten, die über
            Websites erfolgen, die nicht unter der Verantwortung von Ironhack
            stehen.{' '}
          </Text3>
        </Question>
        <Question>
          <Text2 as="h2">PFLICHT DIE DATEN ZU BERMITTELN</Text2>
          <Text3 as="p">
            Die Daten, die auf den Formularen der Website anzugeben sind, müssen
            im allgemeinen zwingend übermittelt werden (es sei denn, im
            dementsprechenden Feld ist Gegenteiliges angegeben), um die
            festgesetzten Bestimmungen zu erfüllen. Sollten sie also nicht
            gegeben oder falsche Angaben gemacht werden, kann demjenigen, was
            der Nutzer beantragt, nicht nachgekommen werden, unbeschadet davon,
            dass der Inhalt der Website problemlos gesehen werden kann.{' '}
          </Text3>
        </Question>
        <Question>
          <Text2 as="h2">
            WOFR VERARBEITET IRONHACK DIE PERSONENBEZOGENEN DATEN DES NUTZERS
            UND FR WIE LANGE
          </Text2>
          <Text3 as="p">
            Die personenbezogenen Daten, die der Nutzer{' '}
            <strong>über das Kontaktformular der Website </strong>angibt, werden
            von Ironhack für Folgendes verarbeitet:{' '}
          </Text3>
          <Text3 as="p">
            <ul>
              <li>
                Um auf Anfragen, Vorschläge und Beschwerden zu antworten, die
                der Nutzer Ironhack unterbreitet. Seine Daten werden zu diesem
                Zweck so lange gespeichert, wie dies für die Bearbeitung seiner
                Anfrage, seines Vorschlags und/oder seiner Beschwerde
                erforderlich ist, und danach, bis die eventuellen aus der
                Anfrage ableitenden Haftungen verjähren.{' '}
              </li>
            </ul>
            Die personenbezogenen Daten, die{' '}
            <strong>
              über das Abonnement des kommerziellen Newsletters der Website{' '}
            </strong>
            gegeben werden, werden von Ironhack für Folgendes verarbeitet:{' '}
          </Text3>
          <Text3 as="p">
            <ul>
              <li>
                Für das Abonnement des kommerziellen Newsletters, damit der
                Nutzer über die neuesten Nachrichten und Veranstaltungen von
                Ironhack informiert wird. Ihre Daten werden gespeichert, bis Sie
                die Einwilligung, die Sie gegeben haben, widerrufen.{' '}
              </li>
            </ul>
            Die personenbezogenen Daten, die über das{' '}
            <strong>Anmeldeformular für Kurse von Ironhack</strong> gegeben
            werden, werden von Ironhack für Folgendes verarbeitet:{' '}
          </Text3>
          <Text3 as="p">
            <ul>
              <li>
                Ihre Anmeldung zu bearbeiten und die erforderlichen Tätigkeiten
                vorzunehmen, die für die Auswahl der Bewerber erforderlich sind.
                Ihre Daten werden zu diesem Zweck so lange gespeichert, wie dies
                für die Bearbeitung der Anmeldung erforderlich ist, und danach,
                bis die eventuellen aus der Anmeldung ableitenden Haftungen
                verjähren.{' '}
              </li>
              <li>
                Für das Abonnement des kommerziellen Newsletters - wenn Sie das
                entsprechende Feld markiert haben –, um Sie über die neuesten
                Nachrichten und Veranstaltungen von Ironhack zu informieren.
                Ihre Daten werden gespeichert, bis Sie die Einwilligung, die Sie
                gegeben haben, widerrufen.{' '}
              </li>
            </ul>
            Die personenbezognen Daten, die
            <strong>
              {' '}
              über das Anmeldeformular als kooperierendes Unternehmen von
              Ironhack{' '}
            </strong>
            übermittelt werden, werden von Ironhack für Folgendes verarbeitet:{' '}
          </Text3>
          <Text3 as="p">
            <ul>
              <li>
                Das Unternehmen, für das Sie arbeiten, als ein mit Ironhack
                kooperierendes Unternehmen einzutragen. Ihre Daten werden
                gespeichert, so lange das Unternehmen als kooperierendes
                Unternehmen gilt, und danach, bis die eventuellen aus der
                Eintragung ableitenden Haftungen verjährt sind.{' '}
              </li>
            </ul>
            Die personenbezogenen Daten, die{' '}
            <strong>
              über das Anmeldeformular im Zusammenhang mit einem Stellenangebot
            </strong>{' '}
            gegeben werden, werden von Ironhack für Folgendes verarbeitet:
          </Text3>
          <Text3 as="p">
            <ul>
              <li>
                Ihre Kandidatur für den Auswahlprozess zu bewerten und
                gegebenenfalls die erforderlichen Handlungen vorzunehmen, damit
                Sie in den Auswahlprozess und in zukünftige Auswahlprozesse, an
                denen Sie interessiert sein könnten, aufgenommen werden. Wenn
                Sie nicht das Gegenteil angeben, werden Ihre Daten für die Dauer
                von einem Jahr ab Ihrer Bewerbung zum Stellenangebot
                gespeichert. Sie sind folglich dafür verantwortlich, die
                besagten Daten stets zu aktualisieren, da sie verwendet werden
                können, um Sie von Angeboten von Ironhack in Kenntnis zu setzen,
                an denen Sie interessiert sein könnten.{' '}
              </li>
            </ul>
          </Text3>
        </Question>
        <Question>
          <Text2 as="h2">
            WELCHE PERSONENBEZOGENEN DATEN WERDEN VON IRONHACK VERARBEITET
          </Text2>
          <Text3 as="p">
            Im Zusammenhang mit dem auf der Website vorhandenen Kontaktformular
            verarbeitet Ironhack die folgenden Daten:{' '}
          </Text3>
          <Text3 as="p">
            <ul>
              <li>Identifikationsdaten: Vorname, Nachname, usw. </li>
              <li>Kontaktdaten: E-Mail, Telefonnummer, usw. </li>
            </ul>
            Im Zusammenhang mit dem auf der Website vorhandenen Formular für das
            Abonnement des kommerziellen Newsletters verarbeitet Ironhack die
            folgenden Daten:{' '}
          </Text3>
          <Text3 as="p">
            <ul>
              <li>Kontaktdaten: E-Mail, usw. </li>
            </ul>
            Im Zusammenhang mit dem auf der Website vorhandenen Anmeldeformular
            für Kurse von Ironhack verarbeitet Ironhack die folgenden Daten:{' '}
          </Text3>
          <Text3 as="p">
            <ul>
              <li>Identifikationsdaten: Vorname, Nachname, usw. </li>
              <li>Kontaktdaten: E-Mail, Telefonnummer, usw. </li>
            </ul>
            Im Zusammenhang mit dem auf der Website vorhandenen Formular für die
            Anmeldung als kooperierendes Unternehmen von Ironhack verarbeitet
            Ironhack die folgenden Daten:{' '}
          </Text3>
          <Text3 as="p">
            <ul>
              <li>Identifikationsdaten: Vorname, Nachname, usw. </li>
              <li>Angaben zum Arbeitsplatz: Jobkategorie. </li>
              <li>Kontaktdaten: E-Mail, Telefonnummer, usw. </li>
            </ul>
            Im Zusammenhang mit der Bewerbung zu einem Stellenangebot
            verarbeitet Ironhack die folgenden Daten:
          </Text3>
          <Text3 as="p">
            <ul>
              <li>
                Identifikationsdaten: Vorname, Nachname, Foto, Steuerident.-Nr.,
                Anschrift, usw.{' '}
              </li>
              <li>Kontaktdaten: Telefonnummer, E-Mail, usw.</li>
              <li>
                Studium und berufsbezogene Daten: Ausbildung/Titel,
                Studiumverlauf, Berufserfahrung, Zugehörigkeit zu Kammern oder
                Berufsverbänden.
              </li>
              <li>
                Detaillierte Angaben zu den Anstellungen: Beruf, Arbeitsplatz,
                Daten der Gehaltsabrechnung (keine finanziellen Angaben),
                Lebenslauf des Arbeitnehmers.
              </li>
              <li>Sonstige Angaben, die im Lebenslauf erscheinen können.</li>
            </ul>
            Sollte der Nutzer Daten von Dritten übermitteln, erklärt er, über
            deren Einwilligung zu verfügen, und er verpflichtet sich, die in der
            Datenschutzerklärung enthaltene Information an sie weiterzuleiten.
            Gleichzeitig enthebt er Ironhack jeder Haftung in diesem Sinne.
            Ironhack kann dies trotzdem periodisch überprüfen und in
            Übereinstimmung mit den Datenschutzbestimmungen die diesbezüglichen
            Due-Diligence-Maßnahmen ergreifen.
          </Text3>
        </Question>
        <Question>
          <Text2 as="h2">COOKIES-POLITIK</Text2>
          <Text3 as="p">
            Klicken Sie{' '}
            <Link href="https://cdn.ironhack.com/marketing/IH_Coookies_Policy.docx">
              hier
            </Link>{' '}
            um unsere Cookie-Politik zu sehen
          </Text3>
        </Question>
        <Question>
          <Text2 as="h2">
            AUF WELCHER GRUNDLAGE IST DIE VERARBEITUNG DER PERSONENBEZOGENEN
            DATEN DES NUTZERS BERECHTIGT
          </Text2>
          <Text3 as="p">
            <ul>
              <li>
                Die Verarbeitung der Daten in Bezug auf die Bearbeitung von
                Anfragen, Vorschlägen und/oder Beschwerden stützt sich auf die
                Einwilligung, die der Nutzer bei Einsendung seines Antrags
                gegeben hat.{' '}
              </li>
              <li>
                Die Verarbeitung der Daten in Bezug auf das Abonnement des
                kommerziellen Newsletters, um Information über die neuesten
                Nachrichten und Veranstaltungen von Ironhack zu erhalten, stützt
                sich auf die Einwilligung, die der Nutzer bei Einsendung seines
                Antrags gegeben hat.{' '}
              </li>
              <li>
                Die Verarbeitung der Daten in Bezug auf die Anmeldung für Kurse
                von Ironhack stützt sich auf die Erfüllung des
                Vertragsverhältnisses zwischen Ironhack und dem Nutzer und somit
                ist die Einwilligung des Nutzers nicht erforderlich.{' '}
              </li>
              <li>
                Die Verarbeitung der Daten in Bezug auf die Anmeldung als
                kooperierendes Unternehmen von Ironhack stützt sich auf die
                Erfüllung des Vertragsverhältnisses und somit ist eine
                Einwilligung nicht erforderlich.{' '}
              </li>
              <li>
                Die Verarbeitung der Daten in Bezug auf die Bewerbung zu
                Stellenangeboten stützt sich auf die Einwilligung des Nutzers.
              </li>
            </ul>
            Berücksichtigen Sie bitte, dass Sie Ihre Einwilligung jederzeit
            zurückziehen können, ohne dass dies die Rechtmäßigkeit der aufgrund
            der Einwilligung bis zum Widerruf erfolgten Verarbeitung
            beeinträchtigt.{' '}
          </Text3>
        </Question>
        <Question>
          <Text2 as="h2">EMPFNGER</Text2>
          <Text3 as="p">Ihre Daten können weitergeleitet werden an: </Text3>
          <Text3 as="p">
            <ul>
              <li>
                Die öffentichen Verwaltungen, wenn dies in den Gesetzen
                vorgesehen ist.{' '}
              </li>
              <li>
                Gerichte und Sicherheitskräfte, wenn dies in den Gesetzen
                vorgesehen ist.{' '}
              </li>
              <li>
                Services für die Verarbeitung der Information für
                Marketingzwecke:
                <ul>
                  <li>Mailchimp</li>
                  <li>Pipedrive</li>
                  <li>Google Analytics</li>
                  <li>Hotjar</li>
                  <li>Facebook Custom Audience</li>
                </ul>
              </li>
            </ul>
          </Text3>
        </Question>
        <Question>
          <Text2 as="h2">INTERNATIONALE DATENBERMITTLUNGEN</Text2>
          <Text3 as="p">
            Die gesammelte Information kann übermittelt werden an:
          </Text3>
          <Text3 as="p">
            <ul>
              <li>Ironhack campuses in Mexico, U.S. &amp; Brazil</li>
              <li>Mailchimp Inc. (U.S.)</li>
            </ul>
          </Text3>
        </Question>
        <Question>
          <Text2 as="h2">VERANTWORTUNG DES NUTZERS</Text2>
          <Text3 as="p">Der Nutzer:</Text3>
          <Text3 as="p">
            <ul>
              <li>
                bestätigt, über 18 Jahre alt zu sein, und dass die an Ironhack
                übermittelten Daten richtig, genau, vollständig und aktuell
                sind. In diesem Sinne haftet der Nutzer für die Richtigkeit
                aller Daten, die er gibt, und er wird die zur Verfügung
                gestellte Information aktualisieren, sodass sie mit der Realität
                übereinstimmt.{' '}
              </li>
              <li>
                bestätigt, dass er Dritte, von denen er eventuell Daten
                übermittelt, von den in diesem Dokument enthaltenen Aspekten in
                Kenntnis gesetzt hat. Er bestätigt auch, dass er ihre
                Genehmigung erhalten hat, um ihre Daten für die oben genannten
                Zwecke an Ironhack weiterzuleiten.
              </li>
              <li>
                Er ist verantwortlich für die falsche oder unrichtige
                Information, die er über die Website gibt, und für den direkten
                oder indirekten Schaden, den Ironhack oder Dritte dadurch
                erleiden.
              </li>
            </ul>
          </Text3>
        </Question>
        <Question>
          <Text2 as="h2">KOMMERZIELLE KOMMUNIKATIONEN</Text2>
          <Text3 as="p">
            Ironhack verarbeitet die personenbezogenen Daten, die die Nutzer
            übermittelt haben, unter anderem für die Versendung von E-Mails mit
            Information über Produkte, Dienstleistungen, Kampagnen, Angebote,
            Veranstaltungen oder relevante Nachrichten für die Nutzer. Wenn
            immer eine solche Kommunikation erfolgt, wird sie ausschließlich an
            die Nutzer gerichtet, die ausdrücklich ihre Einwilligung gegeben
            haben.
          </Text3>
          <Text3 as="p">
            Sollte der Nutzer keine kommerziellen Kommunikationen oder Angebote
            von Ironhack mehr erhalten wollen, kann er sich abmelden, indem er
            eine E-Mail an die folgende Adresse sendet:{' '}
            <a href="mailto:legal@ironhack.com">legal@ironhack.com</a>
          </Text3>
        </Question>
        <Question>
          <Text2 as="h2">IHRE RECHTE</Text2>
          <Text3 as="p">
            Der Nutzer kann sich jederzeit und kostenlos an Ironhack mit einem
            Schreiben richten, das in die Straße Castello Nr. 115B, Local 7,
            28006, Madrid, mit der Referenz “Datenschutz” gesandt wird, oder mit
            einer E-Mail an{' '}
            <a href="mailto:legal@ironhack.com">legal@ironhack.com</a> – unter
            Beilage einer Kopie seines Ausweises:{' '}
          </Text3>
          <Text3 as="p">
            <ul>
              <li>Um gegebene Einwilligungen zu widerrufen. </li>
              <li>
                Um in Erfahrung zu bringen, ob bei Ironhack personenbezogene
                Daten verarbeitet werden, die sich auf den Nutzer beziehen.{' '}
              </li>
              <li>Um auf seine personenbezogenen Daten zuzugreifen. </li>
              <li>
                Um nicht zutreffende oder unvollständige Daten zu berichtigen.{' '}
              </li>
              <li>Um die Löschung seiner Daten zu verlangen. </li>
              <li>
                Um zu erreichen, dass Ironhack die Verarbeitung der Daten
                einschränkt, sobald eine der Bedingungen gegeben ist, die in den
                Datenschutzbestimmungen vorgesehen sind.{' '}
              </li>
              <li>
                Unter gewissen Umständen und aus Gründen, die sich aus seiner
                besonderen Situation ergeben, kann er gegen die Verarbeitung
                seiner Daten (konkreterweise für kommerzielle Zwecke von
                Ironhack) Widerspruch einlegen. Ironhack wird dann die
                Verarbeitung seiner Daten einstellen, es sei denn, es bestehen
                zwingende berechtigte Gründe oder dies dient der Ausübung oder
                Verteidigung von Rechtsansprüchen.
              </li>
              <li>
                Um das direkte Eingreifen einer Person zu erwirken, seinen
                eigenen Standpunkt darzulegen und die von Ironhack getroffenen
                automatisierten Entscheidungen anzufechten.{' '}
              </li>
              <li>Um die Übertragbarkeit seiner Daten zu verlangen. </li>
              <li>
                Eine Beschwerde in Bezug auf den Schutz seiner personenbezogenen
                Daten bei der dementsprechenden Aufsichtsbehörde (im Fall von
                Spanien: die “Agencia Española de Protección de Datos” [
                <em>spanische Datenschutzbehörde</em>]) einreichen.{' '}
              </li>
            </ul>
            Die Ausübung der weiter oben genannten Rechte setzt voraus, dass die
            Anforderungen erfüllt werden, die in den jeweils in Kraft stehenden
            Bestimmungen vorgesehen sind.
          </Text3>
        </Question>
        <Question>
          <Text2 as="h2">SICHERHEITSMASSNAHMEN</Text2>
          <Text3 as="p">
            Ironhack verarbeitet die Daten des Nutzers immer in vertraulicher
            Weise und befolgt dabei die Geheimhaltungspflicht, immer in
            Übereinstimmung mit den anwendbaren Vorschriften. Das Unternehmen
            ergreift in diesem Zusammenhang die erforderlichen technischen und
            organisatorischen Maßnahmen, um die Sicherheit der Daten
            sicherzustellen und deren Veränderung, Verlust, unbefugte
            Verarbeitung oder unbefugten Zugang zu vermeiden, und zwar unter
            Berücksichtigung des Stands der Technik, der Art der gespeicherten
            Daten und des Risikos, denen sie ausgesetzt sind.
          </Text3>
          <Text3 as="p">Letzte Aktualisierung: 10.08.2018</Text3>
          <Text3 as="p">
            <strong>
              Copyright © 2018 Ironhack School S.L. Alle Rechte vorbehalten.{' '}
            </strong>
          </Text3>
        </Question>
      </Layout>
      <Footer page={pageName} />
    </>
  );
};

export const query = graphql`
  query {
    locales: allLocale(
      filter: {
        lng: { eq: "de" }
        ns: { regex: "/(seo)|(breadcrumbs)|(menu)|(banner)/" }
      }
    ) {
      ...TranslationFragment
    }
  }
`;

export default withI18next('de')(PrivacyPolicy);
